<template>
  <v-app-bar app color="primary" dark fixed>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer/>
    <kurcc-search-box v-if="showSearch"/>
    <kurcc-filter v-if="showFilter"/>

    <template v-if="isUpcomingPage || isTodayPage || isGroupPage" v-slot:extension>
      <v-tabs v-if="isUpcomingPage" v-model="currentTab" align-with-title fixed-tabs>
        <v-tab v-for="tab in tabs.upcoming" :key="tab.tab" :to="{name: 'upcoming', query: { tab: tab.tab }}" exact>
          {{ tab.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs v-if="isTodayPage" v-model="currentTab" align-with-title fixed-tabs>
        <v-tab v-for="tab in tabs.today" :key="tab.tab" :to="{name: 'today', query: { tab: tab.tab }}" exact>
          {{ tab.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs v-if="isGroupPage" v-model="currentTab" align-with-title fixed-tabs>
        <v-tab v-for="tab in tabs.group" :key="tab.tab" :to="{name: 'group', query: { tab: tab.tab }}" exact>
          {{ tab.tab }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'KurccAppBar',
  components: {
    KurccSearchBox: () => import('@/modules/app/components/KurccSearchBox'),
    KurccFilter: () => import('@/modules/app/components/KurccFilter')
  },
  data () {
    return {
      currentTab: null,
      tabs: {
        upcoming: [
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.upcoming.routine')
          },
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.upcoming.custom')
          },
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.upcoming.revisit')
          }
        ],
        today: [
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.today.inspections')
          },
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.today.started')
          },
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.today.done')
          }
        ],
        group: [
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.group.inspections')
          },
          {
            tab: this.$vuetify.lang.t('$vuetify.general.appBarTabs.group.members')
          }
        ]
      }
    }
  },
  computed: {
    title () {
      if (this.isGroupPage && this.$route.params.group) {
        return this.$route.params.group.name
      } else {
        return this.$lodash.upperFirst(this.$route.name)
      }
    },
    showFilter () {
      return !this.isGroupsPage && !this.isTodayPage && !this.isMorePage && !this.isSitesPage && !this.isBranchesPage &&
        !this.isGroupMembersPage && !this.isProfilePage && !this.isSettingsPage
    },
    showSearch () {
      return !this.isMorePage && !this.isProfilePage && !this.isSettingsPage
    },
    isUpcomingPage () {
      return this.$route.name === 'upcoming'
    },
    isTodayPage () {
      return this.$route.name === 'today'
    },
    isGroupPage () {
      return this.$route.name === 'group'
    },
    isGroupMembersPage () {
      return this.$route.query.tab === 'members'
    },
    isGroupsPage () {
      return this.$route.name === 'groups'
    },
    isMorePage () {
      return this.$route.name === 'more'
    },
    isSitesPage () {
      return this.$route.name === 'sites'
    },
    isBranchesPage () {
      return this.$route.name === 'branches'
    },
    isProfilePage () {
      return this.$route.name === 'profile'
    },
    isSettingsPage () {
      return this.$route.name === 'settings'
    }
  }
}
</script>
